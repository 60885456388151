@import (css)
    url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';

// Font
@font-family: 'Open Sans', sans-serif;
@font-size-base: 15px;

// Colors
@primary-color: #2ac4c7;
@info-color: #1e90ff;

// Form & inputs
@form-vertical-label-padding: 0 0 6px;
@input-padding-vertical-lg: 5px;
@form-item-margin-bottom: 1rem;

@border-radius-base: 5px;
@font-size-lg: 15px;

// Modal
@modal-body-padding: 18px 24px;

// Tag
@tag-font-size: 13.5px;

// List
@list-item-meta-avatar-margin-right: 12px;

// Tooltip
@tooltip-max-width: 400px;

// Button
@btn-height-sm: 28px;

// Dropdown
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
    vertical-align: 0.1em;
}

button .anticon,
.ant-picker .anticon,
.ant-message-custom-content .anticon,
.ant-select-clear .anticon {
    vertical-align: 0.125em;
}

.ant-form-vertical .ant-form-item-label {
    font-weight: 500;
}

.ant-tag {
    padding: 2.5px 7px !important;
}

.ant-alert-with-description .ant-alert-message {
    font-weight: 500 !important;
}

// Radio group
.ant-radio-group-small .ant-radio-button-wrapper {
    height: 28px;
    padding: 0 7px;
    line-height: 24px;
}

// Table header padding
.ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    padding: 8px 16px;
}

// Empty
.ant-empty {
    p {
        margin: 0;
    }
}

/* You can add global styles to this file, and also import other style files */
.Icon-Vertical-Align {
    vertical-align: 0.125em;
}

.Content-Container {
    padding: 1.8rem;
    position: relative;
    min-height: ~'calc(100vh - 65px)';
}

.Centered-Content {
    max-width: 1130px;
    margin: 0 auto;
}

.Extra-Bottom-Padding {
    padding-bottom: 5rem;
}

.Card {
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    background: #fff;
    padding: 1rem;
}

.Card-Title {
    color: #555;
    font-size: 21px;
    font-weight: 600;
}

.Cta-Button {
    background-color: #3584fb !important;
    border-color: #0066ff !important;

    &:hover {
        background-color: #6aa5fd !important;
        border-color: transparent !important;
    }

    &:focus {
        border-color: #6aa5fd !important;
    }
}

.Green-Button {
    background-color: #1aaa55 !important;
    border-color: #168f48 !important;

    &:hover {
        background-color: #168f48 !important;
        border-color: #12753a !important;
    }

    &:focus {
        border-color: #12753a !important;
    }
}

.Unselectable {
    user-select: none;
    outline: 0;
}

.Ndvi-Unavailable {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.Relative {
    position: relative;
}

.Keyboard-Key {
    padding: 0.1em 0.6em;
    font-size: 11.5px;
    font-weight: 500;
    color: #242729;
    text-shadow: 0 1px 0 #fff;
    background-color: #e1e3e5;
    border: 1px solid #adb3b9;
    border-radius: 3px;
    box-shadow:
        0 1px 0 rgba(12, 13, 14, 0.2),
        0 0 0 2px #fff inset;
}

.Letter-Spacing-25 {
    letter-spacing: -0.25px;
}

.Weight-500 {
    font-weight: 500;
}

.Weight-600 {
    font-weight: 600;
}

.Weight-700 {
    font-weight: 700;
}

.Weight-900 {
    font-weight: 900;
}

.Underline {
    text-decoration: underline;
}

.Center {
    text-align: center;
}

.Full-Width {
    width: 100% !important;
}

.Flex {
    display: flex;
    justify-content: center;
}

.Flex-Row {
    display: flex;
    align-items: center;
}

.Flex-Center-All {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Flex-Column {
    display: flex;
    flex-direction: column;
}

.Flex-Row {
    display: flex;
    align-items: center;
}

.Cursor-Pointer {
    cursor: pointer;
}

/* Enrollment */
.Area-Tag {
    color: white;
    z-index: 125;
    font-weight: 500;
    letter-spacing: -1px;
    text-shadow: 0 0 5px black;
    user-select: none;
    pointer-events: none;
}

.Field-View-Area-Tag {
    font-size: 15.5px;
    color: white;
    z-index: 125;
    font-weight: 500;
    letter-spacing: -0.5px;
    text-shadow: 0 0 5px black;
    user-select: none;
    pointer-events: none;
}

.Length-Tag {
    font-size: 12px;
    color: white;
    opacity: 0.95;
    z-index: 125;
    font-weight: 400;
    letter-spacing: -0.5px;
    text-shadow: 0 0 4px black;
    user-select: none;
    pointer-events: none;
    padding: 1px 3px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

.Distance-Tag {
    font-size: 13.5px;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    color: white;
    padding: 2px 6px;
    opacity: 0.75;
    white-space: nowrap;
    user-select: none;
    pointer-events: none;

    &::before {
        border-top: 6px solid rgba(0, 0, 0, 0.5);
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        content: '';
        position: absolute;
        bottom: -6px;
        margin-left: -6px;
        left: 50%;
    }
}

.Static-Distance-Tag {
    background-color: #c732e5;
    color: white;

    &::before {
        border-top: 6px solid #c732e5;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        content: '';
        position: absolute;
        bottom: -6px;
        margin-left: -6px;
        left: 50%;
    }
}

.Right-Toolbox {
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
    padding-right: 0.75rem;
    flex-direction: column;

    .Zoom-Buttons {
        pointer-events: all;
    }

    .Center-Buttons {
        margin-top: 0.5rem;
        pointer-events: all;
    }
}

.No-Data-Container {
    padding: 1.5rem 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
}

.No-Margin {
    margin: 0 !important;
}

.No-Padding {
    padding: 0 !important;
}

/* Scrollbar */
*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-track {
    background: #ededed;
    background-clip: padding-box;
}

*::-webkit-scrollbar-thumb {
    background-color: #d1d1d1;
    border-radius: 0px;
    border: 0;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
    background: #cacaca;
}
